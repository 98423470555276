import Gap from 'components/Gap';
import { Column, Grid } from 'components/Grid';
import TruncatedText from 'components/TruncatedText';
import { Statement, Title } from 'components/Typography';
import { triggerSelectContent } from 'lib/gtag';
import { HeaderModel } from 'models/contentful/header/types';
import RouterLink from 'next/link';
import { FC } from 'react';
import styled, { css } from 'styled-components';

export interface HeaderProps {
  actions?: {
    title: string;
    link?: string;
    id?: string;
    onClick?: () => void;
  }[];
  inline?: boolean;
  title: HeaderModel['title'];
  titleLink?: string;
  onClickTitle?: () => void;
  uppercase?: boolean;
  headerLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
  headerVariant?: '2' | '3';
  description?: string;
  hideDescriptionOnMobile?: boolean;
  truncate?: boolean;
}

const Wrapper = styled(Grid)<Pick<HeaderProps, 'inline'>>(
  ({ inline }) => css`
    ${inline ? `margin: 0 !important` : ''}
  `
);

const UnstyledLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const Actions = styled.div<Pick<HeaderProps, 'inline'>>(
  ({ inline, theme: { bp } }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${bp.l} {
      ${inline
        ? ''
        : css`
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
          `}
    }

    ${bp.xl} {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  `
);

const ActionTitle = styled(Title)<Pick<HeaderProps, 'inline'>>(
  ({ inline, theme: { bp } }) => css`
    appearance: none;
    padding: 0;
    border: 0;
    background-color: transparent;
    text-decoration: underline;
    color: black;
    margin-right: 0;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;

      ${bp.l} {
        margin-right: 0;
      }
    }

    ${bp.l} {
      ${inline
        ? ''
        : css`
            margin-bottom: 0;
            margin-right: 16px;
          `}
    }

    ${bp.xl} {
      margin-bottom: 0;
      margin-right: 16px;
    }
  `
);

const Description = styled.div<{ hiddenOnMobile?: boolean }>(
  ({ hiddenOnMobile, theme: { bp } }) => css`
    display: ${hiddenOnMobile ? 'none' : 'block'};

    ${bp.l} {
      display: block;
    }
  `
);

const Header: FC<HeaderProps> = ({
  title,
  description,
  hideDescriptionOnMobile,
  truncate,
  titleLink,
  inline = false,
  headerLevel = 'h1',
  headerVariant = '3',
  actions = [],
}) => {
  const actionSize = description ? '2XS' : 'XS';

  const displayTitle = (
    <Title
      as={headerLevel}
      variant={headerVariant}
      size={{ xs: 'XS', l: headerVariant === '2' ? 'S' : 'XS' }}
    >
      {title}
    </Title>
  );

  const handleOnClick = (action: {
    title: string;
    link: string | undefined;
  }) => {
    if (action.link)
      triggerSelectContent(
        'Story CTA',
        title,
        { title: action.title, link: action.link },
        global.window
      );
  };
  return (
    <Wrapper inline={inline} columns={{ xs: 6, l: 12 }}>
      <Column spans={{ xs: 6, l: inline ? 12 : 6, xl: inline ? 12 : 4 }}>
        {titleLink ? (
          <RouterLink legacyBehavior={true} href={titleLink} passHref={true}>
            <UnstyledLink
              onClick={() => handleOnClick({ title, link: titleLink })}
            >
              {displayTitle}
            </UnstyledLink>
          </RouterLink>
        ) : (
          displayTitle
        )}
        {description ? (
          <Description hiddenOnMobile={hideDescriptionOnMobile}>
            <Gap size={8} />
            <Statement as="div" variant="1" size={{ xs: 'XS' }}>
              {truncate ? (
                <TruncatedText text={description} />
              ) : (
                <>{description}</>
              )}
            </Statement>
          </Description>
        ) : null}
        {actions.length ? (
          <>
            <Gap size={!description && actions.length === 1 ? 4 : 16} />
            <Actions inline={inline}>
              {actions.map((action, index) =>
                action.link ? (
                  <RouterLink
                    legacyBehavior={true}
                    key={index}
                    href={action.link}
                    passHref={true}
                  >
                    <ActionTitle
                      as="a"
                      inline={inline}
                      href={action.link}
                      variant="1"
                      size={actionSize}
                      onClick={() =>
                        handleOnClick({
                          link: action.link,
                          title: action.title,
                        })
                      }
                    >
                      {action.title}
                    </ActionTitle>
                  </RouterLink>
                ) : (
                  <ActionTitle
                    key={index}
                    id={action.id}
                    as="button"
                    inline={inline}
                    onClick={action.onClick}
                    variant="1"
                    size={actionSize}
                  >
                    {action.title}
                  </ActionTitle>
                )
              )}
            </Actions>
          </>
        ) : null}
      </Column>
    </Wrapper>
  );
};

export default Header;
