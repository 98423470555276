import { BagCount, Hamburger, Search } from 'components/Icons';
import Logo from 'components/Logo';
import usePageScrolling from 'hooks/usePageScrolling';
import Link from 'next/link';
import { FC, memo, useId } from 'react';
import styled, { css } from 'styled-components';

interface MobileMenuProps {
  dark?: boolean;
  overlay?: boolean;
  bagCount: number;
  locale: string;
  hasNavigation: boolean;
  openSearch: (returnToId: string) => void;
  openNav: (returnToId: string) => void;
}

const LogoLink = styled.a`
  flex: 1 1;
  margin-right: auto;
  -webkit-tap-highlight-color: transparent;

  > * {
    max-width: 100%;
  }
`;

const IconLink = styled.a`
  background: none;
  border: none;
  margin: 0;
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
`;

const Wrapper = styled.nav<{
  dark?: boolean;
  overlay?: boolean;
  isVisible?: boolean;
  isAtTop?: boolean;
}>(
  ({ theme: { bp, colors }, dark, overlay, isVisible, isAtTop }) => css`
    display: flex;
    align-items: center;
    top: 0;
    height: 80px;
    position: sticky;
    padding-left: 15px;
    padding-right: 3px;
    z-index: 1;
    background-color: ${overlay && isAtTop ? 'transparent' : colors.white};
    transform: ${isVisible ? 'translateY(0)' : 'translateY(-100%)'};
    transition-property: background-color, transform;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    user-select: none;

    ${bp.s} {
      padding-left: 18px;
      padding-right: 6px;
    }

    ${bp.l} {
      display: none;
    }

    ${LogoLink} svg {
      path {
        fill: ${dark && isAtTop ? colors.white : colors.black};
      }
    }

    ${IconLink} {
      span {
        color: ${dark && isAtTop ? colors.white : colors.black};
      }

      svg {
        path,
        circle {
          stroke: ${dark && isAtTop ? colors.white : colors.black};
          fill: transparent;
        }
      }
    }
  `
);

const MobileMenu: FC<MobileMenuProps> = ({
  dark,
  overlay,
  bagCount,
  locale,
  hasNavigation,
  openSearch,
  openNav,
}) => {
  const { isScrollingDown, isAtTop } = usePageScrolling();
  const searchButtonId = useId();

  return (
    <Wrapper
      isVisible={isAtTop || !isScrollingDown}
      isAtTop={isAtTop}
      dark={dark}
      overlay={overlay}
    >
      <Link legacyBehavior={true} href={`/${locale}`} passHref={true}>
        <LogoLink aria-label="Home">
          <Logo />
        </LogoLink>
      </Link>
      <IconLink
        id={searchButtonId}
        as="button"
        aria-label="Search"
        onClick={() => openSearch(searchButtonId)}
      >
        <Search />
      </IconLink>
      <Link legacyBehavior={true} href={`/${locale}/bag`} passHref={true}>
        <IconLink aria-label={`Bag (${bagCount})`}>
          <BagCount count={bagCount} />
        </IconLink>
      </Link>
      {hasNavigation && (
        <IconLink
          id="rtid-hamburger"
          as="button"
          aria-label="Open Menu"
          onClick={() => openNav('rtid-hamburger')}
        >
          <Hamburger />
        </IconLink>
      )}
    </Wrapper>
  );
};

export default memo(MobileMenu);
