import RouterLink from 'next/link';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

const FlexLink = styled.a.attrs({ 'data-type': 'CtaBlockLink' })`
  flex: 1;
  display: flex;
  text-decoration: none;
`;

const Link = styled.a.attrs({ 'data-type': 'CtaBlockLink' })`
  text-decoration: none;
`;

interface LinkProps {
  url: string;
  className?: string;
  onClickCallback?: () => void;
  children?: ReactNode;
}

type CtaBlockLinkProps = LinkProps & {
  inline?: boolean;
};

const FlexLinkWrapper: FC<LinkProps> = ({
  url,
  children,
  className,
  onClickCallback,
}) => {
  if (url) {
    return (
      <RouterLink legacyBehavior={true} href={url} passHref={true}>
        <FlexLink href={url} className={className} onClick={onClickCallback}>
          {children}
        </FlexLink>
      </RouterLink>
    );
  }

  return (
    <FlexLink as="div" className={className}>
      {children}
    </FlexLink>
  );
};

const InlineLink: FC<LinkProps> = ({
  url,
  children,
  className,
  onClickCallback,
}) => {
  if (url) {
    return (
      <RouterLink legacyBehavior={true} href={url} passHref={true}>
        <Link href={url} className={className} onClick={onClickCallback}>
          {children}
        </Link>
      </RouterLink>
    );
  }

  return <>{children}</>;
};

const CtaBlockLink: FC<CtaBlockLinkProps> = ({
  inline = false,
  url,
  children,
  className,
  onClickCallback,
}) => {
  const LinkComponent = inline ? InlineLink : FlexLinkWrapper;

  return (
    <LinkComponent
      url={url}
      className={className}
      onClickCallback={onClickCallback}
    >
      {children}
    </LinkComponent>
  );
};

export default CtaBlockLink;
