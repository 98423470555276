import { useEffect, useState } from 'react';

const usePageScrolling = () => {
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);

  let prevScrollPos = 0;

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onScroll = () => {
    const currentScrollPosition = window.scrollY;

    if (currentScrollPosition > prevScrollPos) {
      setIsScrollingDown(true);
    } else {
      setIsScrollingDown(false);
    }

    if (currentScrollPosition < 80) {
      setIsAtTop(true);
    } else {
      setIsAtTop(false);
    }

    prevScrollPos = currentScrollPosition <= 0 ? 0 : currentScrollPosition;
  };

  return { isScrollingDown, isAtTop };
};

export default usePageScrolling;
