import CtaBlockLink from 'components/CtaBlockLink';
import Gap from 'components/Gap';
import Header from 'components/Header';
import Media from 'components/Media';
import { triggerSelectContent } from 'lib/gtag';
import { ResponsiveValue } from 'lib/toResponsiveValue';
import { StorySourceModel } from 'models/contentful/storySource/types';
import { FC } from 'react';

const Story: FC<
  Omit<StorySourceModel, 'blockType'> & {
    vwRelativeWidth?: ResponsiveValue<number>;
  }
> = ({ link, linkLabel, media, header, vwRelativeWidth }) => (
  <>
    <CtaBlockLink
      url={link}
      onClickCallback={() =>
        triggerSelectContent(
          'Story CTA - media',
          media.xs?.image.title || header.title,
          { link, title: header.title },
          global.window
        )
      }
    >
      <Media vwRelativeWidth={vwRelativeWidth} {...media} alt={linkLabel} />
    </CtaBlockLink>
    <Gap size={16} />
    <Header
      inline={true}
      headerLevel="span"
      title={header.title}
      titleLink={link}
      description={header.description}
      actions={header.actions}
    />
  </>
);

export default Story;
