import { Token, marked } from 'marked';
import { FC, useState } from 'react';
import Markdown from 'react-markdown';
import styled from 'styled-components';

interface TruncatedTextProps {
  text: string;
}

const ToggleCta = styled.span`
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
`;

const RichText = styled.div`
  display: inline;

  a {
    color: black;
  }
`;

const splitMarkdown = (tokens: Token[]): string[] => {
  const result: string[] = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'text':
        result.push(...token.text.trim().split(' '));
        break;
      case 'paragraph':
        !!token.tokens && result.push(...splitMarkdown(token.tokens));
        break;
      default:
        result.push(token.raw || '');
    }
  }

  return result;
};

const TruncatedText: FC<TruncatedTextProps> = ({ text }) => {
  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const maxWordCount = 20;

  const truncateText = (text: string): [string, string] => {
    const parsed = marked.lexer(text);
    const words = splitMarkdown(parsed);

    if (words.length > maxWordCount) {
      const truncatedText = words.slice(0, maxWordCount).join(' ');
      const remainingText = words.slice(maxWordCount).join(' ');

      return [truncatedText, remainingText];
    }
    return [text, ''];
  };

  const toggleTextTruncation = () => {
    setIsTextExpanded(!isTextExpanded);
  };

  const truncatedText = truncateText(text);

  return (
    <RichText>
      {isTextExpanded ? (
        <Markdown data-testid="open">{text}</Markdown>
      ) : (
        <>
          <Markdown data-testid="closed">
            {`${truncatedText[0]}${!!truncatedText[1] ? '... ' : ''}`}
          </Markdown>
          {!!truncatedText[1] && (
            <div style={{ display: 'none' }}>
              <Markdown>{truncatedText[1]}</Markdown>
            </div>
          )}
        </>
      )}

      {!!truncatedText[1] && (
        <ToggleCta onClick={toggleTextTruncation}>
          {isTextExpanded ? ' View Less' : ' View More'}
        </ToggleCta>
      )}
    </RichText>
  );
};

export default TruncatedText;
