import { FlashBannerModel } from 'models/contentful/flashBanner/types';
import RouterLink from 'next/link';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface FlashBannerLinkProps {
  url: string;
  children: ReactNode;
}

const Link = styled.a`
  text-decoration: none;
  width: 100%;

  &:focus {
    outline-offset: -1px;
  }

  &:focus > div {
    background: rgba(255, 255, 255, 0.5);
    color: black;
  }
`;

const FlashBannerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  letter-spacing: 0.005em;
  color: white;
  transition-timing-function: ease-in-out;
  width: 100%;
  transition: 0.3s;
  &:hover {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const FlashBannerLink: FC<FlashBannerLinkProps> = ({ children, url }) => {
  return url ? (
    <RouterLink legacyBehavior={true} href={url} passHref={true}>
      <Link href={url}>{children}</Link>
    </RouterLink>
  ) : (
    <>{children}</>
  );
};

interface FlashBannerProps {
  flashBanner?: FlashBannerModel | null;
}

const FlashBanner: FC<FlashBannerProps> = ({ flashBanner }) => {
  if (!flashBanner) {
    return null;
  }

  const { title, link } = flashBanner;

  return (
    <FlashBannerLink url={link}>
      <FlashBannerContent>{title}</FlashBannerContent>
    </FlashBannerLink>
  );
};

export default FlashBanner;
