import DesktopMenu from 'components/DesktopMenu';
import MobileMenu from 'components/MobileMenu';
import useNavigation from 'hooks/useNavigation';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { selectCustomerIsLoggedIn } from 'store/auth/selectors';
import { selectCartCount } from 'store/cart';
import { useLazyDispatch, useSelector } from 'store/hooks';
import { openOverlay } from 'store/overlays';
import { openSearch } from 'store/search';
import { openAuth, openNav } from 'store/sidebar';

interface NavProps {
  dark?: boolean;
  overlay?: boolean;
  locale: string;
}

const Nav: FC<NavProps> = ({ dark, overlay, locale }) => {
  const dispatch = useDispatch();
  const lazyDispatch = useLazyDispatch();

  const bagCount = useSelector(selectCartCount);

  const loggedIn = useSelector(selectCustomerIsLoggedIn);

  const { desktopNavigation, mobileNavigation } = useNavigation();

  const openCountryPicker = useCallback(
    lazyDispatch(
      openOverlay({
        closeOnOutsideClick: true,
        name: 'countryPicker',
        props: {},
      }),
      true
    ),
    []
  );

  const openAuthSidebar = useCallback(
    (returnToId: string) => dispatch(openAuth(returnToId)),
    []
  );

  const openSearchPanel = useCallback(
    (returnToId: string) => dispatch(openSearch(returnToId)),
    []
  );
  const openNavigation = useCallback(
    (returnToId: string) => dispatch(openNav(returnToId)),
    []
  );

  return (
    <>
      <DesktopMenu
        dark={dark}
        overlay={overlay}
        bagCount={bagCount}
        locale={locale}
        loggedIn={loggedIn}
        navigation={desktopNavigation}
        openAuth={openAuthSidebar}
        openSearch={openSearchPanel}
        openCountryPicker={openCountryPicker}
      />
      <MobileMenu
        dark={dark}
        overlay={overlay}
        bagCount={bagCount}
        locale={locale}
        hasNavigation={!!mobileNavigation}
        openSearch={openSearchPanel}
        openNav={openNavigation}
      />
    </>
  );
};

export default memo(Nav);
